import { React, useState, useRef, useEffect } from "react"
import tw from "twin.macro"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import Icon from "../icon"
import Close from "../../images/svgs/close.svg"
import Arrow from "../../images/svgs/arrow.svg"
import Drawer from "./drawer"

const Menu = ({ menuItems, onNavItemClick, collapsed }) => {
  const [openDrawer, setOpenDrawer] = useState("")
  const toggleNavigationDrawer = id => setOpenDrawer(id)

  const navNode = useRef()

  useEffect(() => {
    openDrawer
      ? disableBodyScroll(navNode, { reserveScrollBarGap: true })
      : enableBodyScroll(navNode)
  }, [openDrawer])

  const handleItemClick = () => {
    onNavItemClick()
  }

  const collapsedStyles = tw` lg:top-32 xl:top-22 lg:h-[calc(100vh - 8rem)] xl:h-[calc(100vh - 5.5rem)]`
  const normalStyles = tw` lg:top-52 xl:top-48 lg:h-[calc(100vh - 13rem)] xl:h-[calc(100vh - 12rem)]`

  return (
    <nav ref={navNode} tw="mr-0 w-full lg:w-auto" role="navigation">
      <ul tw="grid gap-y-2 text-2xl font-bold md:text-3xl lg:flex lg:flex-row lg:space-x-5 lg:space-y-0 lg:text-xl xl:mr-7 xl:space-x-7">
        {menuItems.map(({ label, id, childItems }) => (
          <li tw="lg:mb-1" className="global-nav-item" key={`nav-${id}`}>
            <button
              onClick={() => {
                toggleNavigationDrawer(id)
              }}
              tw="flex w-full cursor-pointer items-center justify-between lg:block lg:w-auto"
            >
              <span
                tw="py-3 navLink-animated underline-anthracite lg:underline-lillac"
                css={[openDrawer === id ? tw`navLink-animated-active` : tw``]}
              >
                {label}
              </span>
              <Icon svg={Arrow} tw="h-8 w-5 md:h-9 md:w-6 lg:hidden" />
            </button>
            <div
              tw="absolute left-0 top-0 z-50 flex h-screen w-screen bg-lilac-500 pt-4 md:pt-0"
              css={[
                openDrawer === id ? tw`flex` : tw`hidden`,
                collapsed && collapsedStyles,
                !collapsed && normalStyles,
              ]}
            >
              <div tw="mx-auto flex flex-grow items-start justify-between px-4 pb-2 md:px-10 md:py-7 lg:max-w-screen-2xl lg:py-14 lg:pl-20 lg:pr-20 xl:pl-80">
                <Drawer
                  id={id}
                  label={label}
                  items={childItems}
                  toggleNavigationDrawer={toggleNavigationDrawer}
                  onItemClick={handleItemClick}
                />
                <button
                  tw="mr-2 hidden h-8 w-8 lg:mt-4 lg:block xl:mt-6"
                  onClick={() => {
                    setOpenDrawer("")
                  }}
                >
                  <Icon
                    svg={Close}
                    title="Menü schließen"
                    tw="h-6 w-6 md:h-8 md:w-8"
                  />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default Menu
