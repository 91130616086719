import { React, useEffect, useRef, useState } from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { Turn as Hamburger } from "hamburger-react"
import Menu from "./menu"
import Container from "../../components/container"
// import Twitter from "../social/twitter"
import Instagram from "../social/instagram"
import LinkedIn from "../social/linkedin"
import Bar from "./bar"
import Logo from "./logo"
import { throttle } from "lodash"

const Header = ({ mainMenuItems, footernMenuItems, breadcrumbs }) => {
  const offset = 80
  // const breadcrumbs = props.data?.breadcrumbs?.seo?.breadcrumbs || []
  const [showNavigation, toggleNavigation] = useState(false)

  const handleSelectedNavItem = () => {
    toggleNavigation(false)
  }

  const navNode = useRef()

  useEffect(() => {
    showNavigation
      ? disableBodyScroll(navNode, { reserveScrollBarGap: true })
      : enableBodyScroll(navNode)
  }, [showNavigation])

  const [collapsed, setCollapsed] = useState(false)

  const collapsedStyles = tw`top-18 h-[calc(100vh - 4.5rem)] lg:h-auto`
  const normalStyles = tw`top-28 h-[calc(100vh - 7rem)] lg:h-auto`
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    const updateScrollState = () => {
      if (isBrowser && window.scrollY > offset && !collapsed) {
        setCollapsed(true)
      }
      if (isBrowser && window.scrollY < offset && collapsed) {
        setCollapsed(false)
      }
    }

    if (isBrowser) {
      window.addEventListener("scroll", throttle(updateScrollState, 60))
    }
    return () => {
      if (isBrowser) {
        window.removeEventListener("scroll", updateScrollState)
      }
    }
  }, [collapsed, isBrowser, offset])

  return (
    <Bar collapsed={collapsed}>
      <Container>
        <div tw="flex items-center justify-between sm:flex-row lg:flex-col lg:items-start xl:flex-row xl:items-end">
          <Logo collapsed={collapsed} />
          <div
            ref={navNode}
            tw="absolute left-0 z-20 h-screen w-full flex-col items-start bg-lilac-500 px-4 pb-2 pt-4 sm:px-10 md:py-5 lg:static lg:top-auto lg:-mb-2.5 lg:flex-row lg:items-center lg:justify-between lg:bg-transparent lg:px-0 lg:py-0 lg:pb-0 xl:w-auto xl:justify-evenly"
            css={[
              showNavigation ? tw`flex` : tw`hidden lg:flex`,
              collapsed && collapsedStyles,
              !collapsed && normalStyles,
            ]}
          >
            <Menu
              menuItems={mainMenuItems}
              onNavItemClick={handleSelectedNavItem}
              collapsed={collapsed}
            />
            <div tw="hidden gap-2 lg:mb-2.5 lg:flex">
              <Instagram />
              <LinkedIn />
            </div>
            <div tw="mt-6 hidden flex-grow flex-col items-end justify-end text-sm md:flex lg:hidden">
              <ul tw="font-bold text-xl md:text-2xl">
                {footernMenuItems.map(({ label, url, id }) => (
                  <li tw="" key={`nav-${id}`}>
                    <Link to={url || `/`} tw="inline-block py-2">
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div tw="-mr-2 flex items-center gap-3 lg:hidden">
            <Instagram />
            <div tw="z-40">
              <Hamburger
                toggled={showNavigation}
                label="Navigation anzeigen"
                toggle={state => {
                  toggleNavigation(state)
                }}
              />
            </div>
          </div>
        </div>
      </Container>
    </Bar>
  )
}

export default Header
