import React from "react"
import PropTypes from "prop-types"
import "twin.macro"

const Container = ({ children }) => {
  return (
    <div tw="flex w-full justify-center">
      <div tw="w-full max-w-screen-2xl px-4 sm:px-10 lg:px-20">{children}</div>
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
