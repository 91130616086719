import React, { useId } from "react"
import PropTypes from "prop-types"

const Icon = ({ svg, className, style, title, asImage = false }) => {
  const uniqueId = useId()
  return (
    <svg
      className={className}
      viewBox={svg.viewBox}
      style={style}
      role={asImage ? "img" : undefined}
      aria-labelledby={asImage && title ? uniqueId : undefined}
      aria-hidden={asImage ? "false" : "true"}
    >
      {title && <title id={asImage ? uniqueId : undefined}>{title}</title>}
      <use xlinkHref={svg.url} />
    </svg>
  )
}

Icon.propTypes = {
  svg: PropTypes.object.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  asImage: PropTypes.bool,
}

export default Icon
